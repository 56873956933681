import { CategoryTypes } from "../action_types/category_types";

const initialState = {
  category_info: [],  // ✅ Store categories inside an object property
};

export default function CategoryReducer(state = initialState, action) {
  switch (action.type) {
    case CategoryTypes.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        category_info: [...action.data] || [],  // ✅ Properly updating the category list
      };
    }
    default:
      return state;
  }
}
